*{
  user-select: none;
}

a {
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* Отключает синий фон при нажатии */
}

Link{
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* Отключает синий фон при нажатии */
}

::-webkit-scrollbar {
  display: none;
}

.mainHome{
  height: 90%;
  width: 100%;
}

body {
	margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden; 
  isolation: isolate;
}

button{
  font-family: Muller;
}

.App {
  margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  background: #000000;
  color: white;
  font-family: Muller;
  font-size: 2.2vh ;
}

.App p{
 margin: 0;
}

.info {
  width: 100%;
  height: 7.5%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info img{
  width: 100%;
  height: 100%;
}

.Txt{
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 2.2vh;
}
.Txt p{
  margin-top: 0.6vh;
}

.Txt img {
  height: 40%;
  width: auto;
  margin-right: 1vh;
}

.main {
  width: 100%;
  height: calc(20% - 2vh);
  display: flex;
  align-items: center;
  justify-content: center;  
}
.main img{height: 70%;}

.MintCoin{
  height: 24%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#halfMinttx{
  justify-content: center;
}

.MintCoin img{
  height: 70%;
}

.MintCoin p{
  font-size: 5vh;
}

#endtxt{
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 23vmax;
}

.MainCoin{
  height: 4%;
  margin-bottom: 2vh;
  width: 100%;
  font-size: 4vh;
}

.Menu {
  height: 68.5% ;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.Reward {
  height: 8%;
  width: 90%;
  display: flex;
  align-items: end;
  justify-content: start;
  font-size: 2.1vh;
  font-weight: bold;
  position: relative;
}

.EllipsSkroll {
  position: absolute;
  top: 0.5vh; 
  left: 50%; 
  transform: translateX(-50%); 
}

.EllipsSkroll img{
  height: 1vh;
  margin-right: 0.3vh;
  margin-left: 0.3vh;
}

.Skroll_Menu_Border{
  width: 100%;
  height: 26%;
  overflow-y: hidden;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.MenuBorder{
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: inline-flex;
  margin-right: 1.7vh;
  align-items: center;
  justify-content: center;
  
}

#orangeBack{
  background: rgba(208, 108, 36, 0.4);
}

#greenBack{
  background: rgba(103, 159, 56, 0.5);
}

@keyframes shadowPulse {
  0% {
    box-shadow: inset 0 0 0 0.1vh rgba(103, 159, 56, 1),
                inset 0 0 10vh 0.8vh rgba(0, 0, 0, 0.5),
                0 0 1.5vh 0.4vh rgba(103, 159, 56, 1);
  }
  50% {
    box-shadow: inset 0 0 0 0.1vh rgba(103, 159, 56, 1),
                inset 0 0 10vh 0.8vh rgba(0, 0, 0, 0.5),
                0 0 2vh 0 rgba(103, 159, 56, 0.8);
  }
  100% {
    box-shadow: inset 0 0 0 0.1vh rgba(103, 159, 56, 1),
                inset 0 0 10vh 0.8vh rgba(0, 0, 0, 0.5),
                0 0 1.5vh 0.4vh rgba(103, 159, 56, 1);
  }
}

#lightGreenBack {
  background: rgba(103, 159, 56, 1);
  box-shadow: inset 0 0 0 0.1vh rgba(103, 159, 56, 1),
              inset 0 0 10vh 0.8vh rgba(0, 0, 0, 0.5),
              0 0 1.5vh 0.4vh rgba(103, 159, 56, 1);
  animation: shadowPulse 1.5s infinite;
}

#lightGray{
  color: #BBBBBB;
}

.flex_menu_border{
  background: #1A1A1A;
  border-radius: 2vh;
  width: 90%;
  height: 85%;
  display: flex;
}

.rightFlex{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.leftFlex{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.leftFlex img{
  height: 75%;
  padding-right: 1.7vh;
}

#nick img {
  height: 100%;
  padding: 0;
  z-index: 2;
  border-radius: 2vh;
}

.MenuBorder p{margin-left: 1.7vh;}

.emoji{
  position: absolute;
  right: -3vh;
  bottom: 0.1vh;
}

#up{ 
  font-size: 2.1vh;
  height: 30%;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
}

#up p{
  padding-top: 1.7vh;
}

#dp {
  font-size: 1.7vh;
  height: 20%;
  text-align: left;
  display: flex;
  align-items: center;
}

#dpp {
  font-size: 1.7vh;
  height: 20%;
  text-align: left;
  display: flex;
  align-items: center;
  padding-top: 0.7vh;
}

.MenuBtn{
  display: flex;
  height: 45%;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #9F9F9F;
  font-size: 1.8vh;
}

.MenuBorder button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  height: 3.3vh;
  width: 5vh;
}

.MenuBtn img {height:4vh;margin-left: 1.7vh;}

.Tasks{
  height: 66%;
  width: 90%;
  display: flex;
  flex-direction: column;
  font-size: 1.8vh;
}

#TaskswithoutNft{
  height: 63%;
}

.TS{
  width: 100%;
  height: 8.4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tsPhoto img{
  height: 60%;
  margin-right: 1.2vh;
}

.tsPhoto{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tsPhoto p{
  font-size: 1.8vh;
}

.footerItemImgWrapper img {
  height: 3.2vh;
}

.BTNLow{
  height: 10%;
  background: #0F0F0F;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
}

.footerItems {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 0.6vh;
  padding-right: 0.6vh;
  width: 100%;
  height: 100%;
}

.footerItem {
  display: flex;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerItemLabel {
  font-size: 1.7vh;
  font-weight: 400;
  padding: 0;
  padding-bottom: 0;
  margin: 0;
}

@font-face {
  font-family: Muller;
  src: url(/src/fonts/MullerRegular.ttf) format('truetype');
}

@font-face {
  font-family: MullerLight;
  src: url(/src/fonts/MullerLight.ttf) format('truetype');
}

@font-face {
  font-family: MullerMedium;
  src: url(/src/fonts/MullerMedium.ttf) format('truetype');
}

@keyframes fade-out  {
  from {opacity: 1;}
  to   {opacity: 0;}
}

@keyframes fade-in {
  from {opacity: 0;}
  to   {opacity: 1;}
}

button{
  border: none;
  color: #000000;
}

.blk{
  height: 100%;
  width: 100%;
  position: absolute;
  background: #000000;
  z-index: 1000;
}

#galo4ka{
  height: 2.5vh;
  margin-left: 1vh;
}

#plus{
  margin: 0;
  display: inline;
}

.img-dark {
  filter: brightness(40%);
}


.nft-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  overflow: visible; 
}

.nft-text h2 {
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  color: #ffffff;
  margin-left: 1.7vh;
  margin-top: 1vh;
  white-space: nowrap;
}

#highlight {color: #ff7f00 !important; }
#highgreen {color: #27D024 !important; }
#highpink {color: #F7667A !important; position: relative; }


.nft-text p {
  white-space: normal; 
  text-align: left; 
  font-size: 1.8vh;
  margin-left: 1.7vh;
  white-space: nowrap;
}

.nft-buttons {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 50%;
}



#not-completed {
  color: red;
  height: 80%;
  width: 100%;
  margin-left: 1.7vh;
  margin-right: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  background: #484848;
  animation: fadeOut 2s forwards;
}


.nft-image img {
  right: 0;
  height: 100%;
  border-radius: 2vh;
}

.nft-image{
  height: 100%;
}

#friends-count {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 80%;
  width: 100%;
  margin-left: 1.7vh;
  margin-right: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  background: #484848;
}

#friends-count p{
  margin-top: 0.2vh;
}

#friends-count img {
  margin-left: 0.5vh;
  height: 2.5vh;
}

#not-completed-missed
{
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 80%;
  width: 100%;
  margin-left: 1.7vh;
  margin-right: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  background: #484848;
}

#not-completed-missed p
{
  margin-top: 0.2vh;
}

#not-completed , #not-completed-missed img {
  margin-left: 0.5vh;
  height: 2.5vh;
  margin-right: 0.5vh; 
}

.referral-button {
  background-color:  #E46E19;
  color: white; 
  height: 80%;
  width: 100%; 
  margin-left: 1.7vh;
  margin-right: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
}


.mint-button {
  background-color: #E46E19;
  color: white;
  width: 100%; 
  height: 80%;
  border-radius: 1.2vh;
  padding-top: 0.4vh;
  font-size: 2.2vh;
  margin-left: 1.7vh;
  margin-right: 1.7vh;
  font-family: MullerMedium;
}

.mint-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

button[data-tc-connect-button="true"]{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #067CFB;
  width: 100000px;
  height: 100000px;
}

#nickContainer{
  height: 20%;
}

#pass{
height: 6vh;
position: absolute;
left: -6.8vh;
top: -2.3vh;
}

.nickDiv {
  height: 50%;
  background: #393939;
  margin-left: 1.7vh;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nickDiv p {
  margin: 0;
  font-size: 1.2vh;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-right: 1vh;
}

.nickDiv img {
  margin: 0.2vh 0.5vh 0.2vh 1vh;
  height: 1.5vh;
}

#Greentxt {
  margin-right: 0.5vh;
  color: #19D94F;
}

#Redtxt {
  color: #E33629;
  margin-right: 0.5vh;
}

#Nicknm {
  flex-direction: column;
}

button[data-tc-dropdown-button="true"] {
  background-color: #585858; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 10vh;
}

@media (orientation: landscape) {

  .leftFlex{
    align-items: center;
    justify-content: end;
  }
  
  .leftFlex img{
    height: 55%;
    padding-right: 1vh;
    padding-top: 0;
  }

  .BTNLow {
    height: 20%;
  }

  .footerItemImgWrapper img {
    height: 8vh;
  }

  .footerItemLabel {
    font-size: 3vh;}

  .Txt{
    font-size: 2.5vh;
  }

  .Fr_Window {
    height: 80%;
  }
  
  .Tasks{
    height: 45%;
  }

  .Reward{
    height: 5%;
  }

  .Skroll_Menu_Border{
    height: 50%;
  }

  .mainHome{
    height: 80%;
   
  }
  .Menu{
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .nft-buttons {
    width: 100%;
    height: 50%;
  }

  #up{ 
    font-size: 4vh;
     }
  
  #dp {
    font-size: 3vh;
  }

  .MenuBtn{

    font-size: 3vh;
  }
  .MenuBtn img {height:6vh;margin-left: 1.7vh;}
  
  .emoji{
    right: -6vh;
  }
}

@media screen and (max-width: 350px) {
  .nft-text p {
    font-size: 1.4vh;
  }

  .nft-text h2 {
    font-size: 2vh;
  }
}
