.mainAlert{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade-in 0.5s forwards;
    
}

.AlertBorder{
    background: #1A1A1A;
    width: 80%;
    display: flex;
    height: 20%;
    border: 0.3vh solid #E10000;
    animation: alertanim 0.3s forwards;
}

.Nft50{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

#NFt50Photo{width:30%;}

.Nft50 h1{
    white-space: nowrap;
    margin: 0;
    color: #E10000;
    font-size: 3.5vh;
}

.Nft50 p{
    white-space: nowrap;
    text-align: start;
    font-size: 2vh;
}

.Nft50 img{
    height: 100%;
    width: auto;
    position: absolute;
    right: 0;
    bottom: 0;
}

@keyframes alertanim {
    from {
      margin-bottom: 50vh;
    }
    to {
        margin-bottom: 0vh;
    }}