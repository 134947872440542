
.Chk_Info{
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vh;
}

.Load{
    height: 40%;
    width: 85%;
    margin-bottom: 4vh;
}

.CheckLoad{
    height: 20%;
    width: 100%;
}

.CheckUp{
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8vh;
}

.CheckUp img{
    height: 50%;
}
.CheckDown{
    height: 50%;
    width: 100%;
}

#checkwindow{
    justify-content: space-between;
}

#checkBtn{
    margin: 8vh;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.ProgressCheck {
    width: 0;
    height: 25%;
    background: #D06C24;
    transition: width 1.5s ease-in-out;
    border-radius: 2vh;
  }
  
  #checkBtn.visible {
    visibility: visible;
    opacity: 1;
  }

  .grayscale {
    filter: grayscale(100%);
    transition: filter 0.5s ease-in-out;
  }