.NFTs_Window{
  width: 100%;
  height: 90%;
  position: fixed;
  flex-direction: column;
  top: 0;
  color: white;
  z-index: 1001;
  animation: fade-in 0.3s forwards;
}

.mainNft{
  height: 92.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  width: 100%;
}

.Shapka{
  height: 7.5%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.shapkaborder{
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.shapkaborder p{
  margin-top: 0.6vh;
  font-size: 2vh;
  text-align: left;
  white-space: nowrap;
}

.shapkaborder img{
  height: 65%;
  margin: 1vh; 
  filter: drop-shadow(0 0 4mm #0088CC);
}

#shapka2{
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.feikton{
  width: 80%;
  height: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  border-radius: 1.2vh; 
  margin-top: 2vh;
  left: 10%;
}

.nft-promo {
  display: flex;
  align-items: center;
  align-self: start;
  margin-left: 1.5vh;
  justify-content: space-between;
  background-color: #1A1A1A;
  position: relative;
  border-radius: 2vh 2vh 2vh 0;
  width: 90%;
  height: 30%;
  margin-top: 9.5vh;
}

.canMint{
  filter: saturate(0%);
  touch-action: none;
}

.greenlight{
  background: #397446;
  touch-action: none;
}

.marvel{height: 100%; z-index: 2; position: absolute; left: 0;}

#ros{left: auto; right: 0;}



#redlight{
  background: #FF3B3B;
}


.nft-promo2{
  display: flex;
  margin-right: 1.5vh;
  background-color: #1A1A1A;
  border-radius: 2vh 2vh 0 2vh;
  align-self: end;
  width: 90%;
  position: relative;
  height: 45%;
  margin-top: 9.5vh;
}

.nft-promo2 img{
  height: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.LeftNft2{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.LeftNft2 p{
  white-space: nowrap;
  text-align: start;
  margin-left: 1.7vh;
  font-size: 1.8vh;
}

.LeftNft2 h1{
  white-space: nowrap;
  text-align: start;
  margin: 0;
  margin-left: 1.7vh;
  margin-top: 1vh;
  font-size: 2vh;
  font-weight: 600;
}

.rightNft2{
  height: 100%;
  width: 0%;
}

.custom-list {
  list-style-type: disc;
  white-space: nowrap;
  text-align: start;
  margin: 0;
  padding-left: 2vh;
  margin-left: 3vh;
}

.custom-list li {
  font-size: 1.8vh;
  line-height: 2.2vh;
}

.sendButtonm{
  background-color:  #E46E19;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  height: 14%;
  width: 54%;
  margin-left: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  position: relative;
  margin-bottom: 1vh;
}

.sendButtonm1{
  background-color:  #397446;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  height: 14%;
  width: 54%;
  margin-left: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  position: relative;
  margin-bottom: 1vh;
}

.FillButtonm{
  background-color: #067CFB;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  height: 14%;
  width: 54%;
  margin-left: 1.7vh;
  font-size: 1.7vh;
  font-family: MullerMedium;
  border-radius: 1.2vh;
  margin-bottom: 1vh;
  position: relative;
  margin-bottom: 1vh;
  animation: shadowPulseBlue 1.5s infinite;
}


#redElipse{
  top: 2vh;
  position: absolute;
  border-radius: 50%;
  border: 0.4vh solid #DD0000;
  background: #940000;
  right: 2vh;
  height: 2vh;
  width: 2vh;
  animation: shadowPulseRed 1.2s infinite ;
}


@keyframes shadowPulseBlue {
  0%  {box-shadow: 0 0 1vh 0.4vh #067CFB;}
  50% {box-shadow: 0 0 2vh 0 #067CFB;}
  100%{box-shadow: 0 0 1vh 0.4vh #067CFB;}
}


@keyframes shadowPulseRed {
  0%  {box-shadow: 0 0 2vh 0.6vh #940000;}
  50% {box-shadow: 0 0 1vh 0 #940000;}
  100%{box-shadow: 0 0 2vh 0.6vh #940000;}
}


.sendButtonm img{
  height: 65%;
  margin-left: 1.7vh;
  position: relative;
}

.sendButtonm1 img{
  height: 65%;
  margin-left: 1.7vh;
  position: relative;
}
/* ///// Switch*/


.switch3
{
  position: absolute;
  right: 5%;
  height: 6%;
  width: 90%;
  display:flex;
  align-items: center;
  flex-direction: row;
  justify-content:space-evenly;
  background: #4F4F4F;
  border-radius: 1vh;
  bottom: 2vh;
}

.switch3::before{
  content: '';
  position: absolute;
  left:0;
  width: 34%;
  height: 100%;
  background: #2C2C2C;
  border-radius: 1vh;
  opacity: .5;
  transition: .5s left ease;
}

.switch3 label{
  height: 100%;
  color: aliceblue;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;
  font-size: 1.7vh;
}
#switch3-radio1, #switch3-radio2, #switch3-radio3{visibility: hidden; position: absolute;}

.switch3:has(#switch3-radio1:checked)::before{
  left: 0%;
}
.switch3:has(#switch3-radio2:checked)::before{
  left: 33%;
}
.switch3:has(#switch3-radio3:checked)::before{
  left: 66%;
}


.mainNftMissed{
  height: 92.5%;
  background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mainNftComplated{
  height: 92.5%;
  background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#NFTPAGEImg{
  height: 55%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.NftpageDiv{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  font-size: 3vh;
  margin-top: 25vh;
}
.NftpageDiv p{
  filter: drop-shadow( 0 0 2vh  #fff)
}

@media (orientation: landscape) {
    .NFTs_Window {height: 80%;}
}
