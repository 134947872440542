.Fr_Window {
    width: 100%;
    height: 90%;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 0;
    color: white;
    background: #000000;
    z-index: 1001;
    animation: fade-in 0.3s forwards;
    overflow: scroll;
}

@media (orientation: landscape) {
    .Fr_Window {
      height: 80%;
    }
  }

.Fr_Info{
  height: 18%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vh;
}

.Fr_Main{
    height: 32%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
}
.Fr_Main img {height: 66%; }

.Fr_InviteBtn{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BTNInvete{
    width: 88%;
    height: 85%;
    background: #D06C24;
    border-radius: 2.5vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 2.5vh;
}
.BTNInvete p{margin: 1vh;}
#Fr_dark{ color: #000000; font-size: 1.7vh;    font-weight: bold; font-family: MullerLight;}

.Fr_Friends{
    width: 88%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 2.5vh;
}

.Fr_list{
    width: 88%;
    height: 30%;
}

.Fr_Frend{
    width: 100%;
    height: 9vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FrPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: white;
}

.FrPhoto img{
    height: 60%;
}

.FrPhoto p{
    font-size: 1.8vh;
}

#FR_Coins_frend p{
    font-size: 2.2vh;
    margin: 0;
}
