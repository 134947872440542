.P2E_Window{
    width: 100%;
    height: 90%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    color: white;
    background-size: cover;
    font-size: 5vh;
    z-index: 1001;
    animation: fade-in 0.3s forwards;
    overflow: hidden;
}

#planet{
    left: -20%;
    height: 100%;
    width: auto;
    z-index: 1;
}

#soontext{
    height: 14%;
    top: 18%;
    filter: drop-shadow( 0px 0px 3vh rgba(0, 0, 0, 60%));
}
#starship{
    height: 35%;
    right: 60%;
    top: -8%;
    rotate: -10deg;
    z-index: 2;
}

#cosmo{ 
    bottom: 0;
    height: 70%;
    filter: drop-shadow( 0px 0px 3vh rgba(0, 0, 0, 60%));
}

.P2E_Window img{
    z-index: 3;
    position: absolute;
}

@media (orientation: landscape) {
    .P2E_Window {
      height: 80%;
    }

    #planet{
        top: 0;
        left: 0;
        height: auto;
        width: 108%;
    }

    
}






@keyframes move-background {
    from {
          -webkit-transform: translate3d(0px, 0px, 0px);
      }
      to { 
          -webkit-transform: translate3d(1000px, 0px, 0px);
      }
  }
  @-webkit-keyframes move-background {
    from {
          -webkit-transform: translate3d(0px, 0px, 0px);
      }
      to { 
          -webkit-transform: translate3d(1000px, 0px, 0px);
      }
  }
  
  @-moz-keyframes move-background {    
      from {
          -webkit-transform: translate3d(0px, 0px, 0px);
      }
      to { 
          -webkit-transform: translate3d(1000px, 0px, 0px);
      }
  }
  
      @-webkit-keyframes move-background {
      from {
          -webkit-transform: translate3d(0px, 0px, 0px);
      }
      to { 
          -webkit-transform: translate3d(1000px, 0px, 0px);
      }
  }
  
  .background-container{
      position: fixed;
      top: 0;
      left:0;
      bottom: 0;
      right: 0;
  }
  
  .stars {
    background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 0;
  }
  
.twinkling{
    width:10000px;
    height: 100%;
    background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    animation:move-background 15s linear infinite;    
}
  

.clouds{
	width:10000px;
	height: 100%;
	background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png") repeat;
	background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 50%;
    z-index: 4;


  animation:move-background 50s linear infinite;
}
