
#YearBTN{
    margin: 8vh;
}

.Story{
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.StoryOne{
    background: #D06C24;
    width: 38%;
    height: 10%;
    margin: 0.5vh;
    border-radius: 3vh;
}

.StoryTwo{
    background: #323232;
    width: 38%;
    height: 10%;
    margin: 0.5vh;
    border-radius: 3vh;
}

.YearTxt{
    font-size: 3.5vh;
    height: 10%;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 1;
}

.YearMain{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-size: 15vh;
}

.YearMain img{
    height: 40%;
    margin-bottom: 4vh;
    z-index: 1;
    overflow: visible;
}

.YearMain p{
    z-index: 2;
}
#Ytxt{
    font-size: 4vh;
}

#Salut {
    position: absolute;
    height: 145%;
    margin-top: 10vh;
    z-index: 0;
    overflow: visible;
    opacity: 0.3;
}

#Star {
    position: absolute;
    height: 90%;
    margin-top: 10vh;
    z-index: 0;
    overflow: visible;
}